<template>
	<TnIcon
		name="spinner"
		class="spinner"
		:size="size"
		:color="color"
	/>
</template>

<script>
import sizes from "./definitions/sizes";
import colors from "./definitions/colors";

/**
 * The Spinner component can be used whenever there is need to signal that some state of the app is loading
 * @displayName TnSpinner
 */
export default defineComponent({
	name: "TnSpinner",

	props: {
		/**
		 * Color of the circles in the spinner onscreen
		 * @values blue, lightblue, black
		 */
		color: {
			type: String,
			default: "blue",
			validator: (value) => {
				return colors.includes(value);
			},
		},
		/**
		 * Size of the spinner (t-shirt sizes)
		 */
		size: {
			type: String,
			default: "m",
			validator: (value) => {
				return sizes.includes(value);
			},
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;

.spinner {
	animation: rotation 2s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
</style>
